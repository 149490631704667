/*jslint node: true */
"use strict";
/*global window, module, document, navigator */

const coman = require('./coman.js');
const moment = require('moment');

const NWIDTH = 250;

const DatePicker = coman.createComponent({
    init: function (options) {
        const self = this;
        self.setMinDate(options.minDate || new Date());
        self.setMaxDate(options.maxDate || new Date(self.minDate.getTime() + 86400000 * 450));
        self.selDate = options.selDate || null;
        self.enabled = options.enabled || true;
        self.nVisibleMonths = options.nVisibleMonths || 2;
        self.calendarVisible = options.calendarVisible || false;
        self.lastRender = options.lastRender || 0;
    },
    render: function () {
        const self = this;
        moment.locale(self.lang);
        let html = "<div ";

        html += " id=\"" + self.uid + "\"";

        // class
        html += " class=\"datePicker ";
        html += self.options.class.join(" ");
        html += "\" ";

        html += ">";

        const classes = [];
        classes.push("inputBox");

        if (!self.selDate) {
            classes.push("empty");
        }

        if (self.enabled) {
            classes.push("enabled");
        } else {
            classes.push("dissabled");
        }

        let selectable;
        // if ((self.selDate >= self.minDate) && (self.selDate<= self.maxDate)) {
        if (self.selDate >= self.minDate) {
            if (typeof self.isSelectable === "function") {
                selectable = self.isSelectable(self.selDate);
            } else {
                selectable = true;
            }
        } else {
            selectable = false;
        }
        if (selectable) {
            classes.push("valid");
        } else {
            classes.push("invalid");
        }

        html += '<span id="' + self.uid + '_input' + '" ';
        html += 'class="' + classes.join(" ") + '" ';
        if (self.enabled) {
            html += self.addEvent("onClick", function (event) {
                if (self.calendarVisible) {
                    self.hidePopup();
                } else {
                    self.showPopup();
                }
            });
        }

        html += ">";

        if (self.selDate) {
            html += moment(self.selDate).startOf('day').format('dddd LL');
        } else {
//            html += self.gettextCatalog.getString("Select date");
            if (self.options.title) {
                html += self.options.title;
            }
        }

        html += "</span>";

        if ((self.calendarVisible) && (self.enabled)) {
            html += self.renderPopup();
        }
        html += "</div>";

        return html;


    },
    renderMonth: function (month, year) {
        const self = this;
        const weekShortDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        let html = "";
        html += '<div class="month">';
        html += '<div class="month_title">' + moment.months()[month - 1] + ' ' + (year) + '</div>';
        html += "<table>";
        html += '<tr>';

        let i;

        for (i = 0; i < 7; i++) {
            let cls = weekShortDay[(moment.localeData().firstDayOfWeek() + i) % 7];
            html += '<th class="' + cls + '">' + moment.weekdaysMin()[(moment.localeData().firstDayOfWeek() + i) % 7] + '</th>';
        }
        html += '</tr>';

        const d = new Date(year, month - 1);

        while (d.getDay() !== moment.localeData().firstDayOfWeek()) {
            d.setDate(d.getDate() - 1);
        }

        const onClick = function (ev, d) {
            d = new Date(d);
            self.setSelDate(d);
            self.hidePopup();
            ev.preventDefault();
            ev.stopPropagation();
        };

        const onMouseOver = function (ev, d) {
            self.pendingOut = false;
            self.setHoverDate(new Date(d), "over");
        };

        self.lastRender = (new Date()).getTime();
        self.pendingOut = false;

        const onMouseOut = function (ev, d) {
            const now = (new Date()).getTime();
            if (now - self.lastRender < 300) {
                self.pendingOut = true;
                setTimeout(function () {
                    if (self.pendingOut) {
                        self.pendingOut = false;
                        self.setHoverDate(self.selDate, "outin");
                    }
                }, 300);

                return;
            } else {
                // console.log("diff: " + (now- self.lastRender));
            }
            self.pendingOut = false;
            self.setHoverDate(self.selDate, "out");
        };

        while ((d.getMonth() !== month % 12) || (d.getDay() !== moment.localeData().firstDayOfWeek())) {
            if (d.getDay() === moment.localeData().firstDayOfWeek()) {
                html += "<tr>";
            }

            if (d.getMonth() === month - 1) {
                let classes = [];

                let selectable;
                if ((d >= self.minDate) && (d <= self.maxDate)) {
                    if (typeof self.isSelectable === "function") {
                        selectable = self.isSelectable(d);
                    } else {
                        selectable = true;
                    }
                } else {
                    selectable = false;
                }

                if (selectable) {
                    classes.push("selectable");
                }
                if ((self.hoverDate) && (self.hoverDate.getTime() === d.getTime())) classes.push("mouseover");

                classes.push(weekShortDay[d.getDay()]);

                if (self.dayClasses) {
                    classes = classes.concat(self.dayClasses(d));
                }

                html += '<td ';
                html += 'class="' + classes.join(" ") + '" ';
                if (selectable) {
                    html += self.addEvent('onclick', onClick, d);
                    html += self.addEvent('ontouchend', onClick, d);
                    html += self.addEvent('onmouseenter', onMouseOver, d);
                    html += self.addEvent('onmouseleave', onMouseOut, d);
                }
                html += ">";

                html += d.getDate();
                html += "</td>";
            } else {
                html += '<td></td>';
            }


            if (d.getDay() === (moment.localeData().firstDayOfWeek() - 1) % 7) {
                html += "</tr>";
            }

            d.setDate(d.getDate() + 1);
        }

        html += "</table>";
        html += '</div>';

        return html;

    },
    renderPopup: function () {

        function getPosition() {
            let style = '';

            let el = document.getElementById(self.uid + '_input');

            if (el == null) {
                return style;
            }

            const elw = el.clientWidth;
            const elh = el.clientHeight;
            let ex, ey;
            /*            for (ex=0, ey=0;
                             el !== null;
                             ex += el.offsetLeft, ey += el.offsetTop, el = el.offsetParent);
            */
            const boundingRect = el.getBoundingClientRect();
            ex = boundingRect.left;
            ey = boundingRect.top;

            /*          ex =  el.offsetLeft;
                        ey = el.offsetTop;
            */
            el = document.getElementById(self.uid + '_input');
            const w = window.innerWidth;
            const h = window.innerHeight;
            const puw = self.nVisibleMonths * NWIDTH;
            const puh = 300;

            if (ex + puw < w) {
                style += "left: " + ex + 'px; ';
            } else {
                style += "right: 0px; ";
            }

            if (ey + elh + puh > h) {
                style += "bottom: " + (h - ey + 2) + 'px; ';
            } else {
                style += "top: " + (ey + elh + 2) + 'px; ';
            }

            return 'style="' + style + '" ';
        }

        const self = this;
        let enabled;

        let html = "";

        html += '<div class="fullScreenPopup" ';
        html += self.addEvent("onClick", function (event) {
            self.hidePopup();
        });
        html += '>';

        html += '<div class="datePickerPopup" ';
        html += getPosition();
        html += self.addEvent("onClick", function (event) {
            event.stopPropagation();
        });
        html += '>';

        let classes = [];

        if (self.options.title) {
            html += '<div class="popupTitle">';
            html += self.encodeStr(self.options.title);
            html += '</div>';
        }

        classes.push("closeButton");
        html += '<div ';
        html += 'class="' + classes.join(' ') + '" ';
        html += self.addEvent('onclick', function () {
            self.hidePopup();
        });
        html += ">";
        html += "<span></span>";
        html += "</div>";


        classes = [];
        classes.push("monthnav");
        classes.push("less");
        if (self.monthOffset > 0) {
            enabled = true;
            classes.push("enabled");
        } else {
            enabled = false;
            classes.push("disabled");
        }
        html += '<div ';
        html += 'id="' + self.uid + '_less" ';
        html += 'class="' + classes.join(' ') + '" ';
        html += self.addEvent('onclick', function () {
            self.monthOffset -= 1;
            self.updatePlusLess();
            self.scrollEffect();
        });
        html += ">";
        html += "<span> &lt; </span>";
        html += "</div>";

        classes = [];
        classes.push("monthnav");
        classes.push("plus");
        if (self.monthOffset < self.nTotalMonths - self.nVisibleMonths) {
            enabled = true;
            classes.push("enabled");
        } else {
            enabled = false;
            classes.push("disabled");
        }
        html += '<div ';
        html += 'id="' + self.uid + '_plus" ';
        html += 'class="' + classes.join(' ') + '" ';
        html += self.addEvent('onclick', function () {
            self.monthOffset += 1;
            self.updatePlusLess();
            self.scrollEffect();
        });
        html += ">";
        html += "<span> &gt; </span>";
        html += "</div>";

        html += '<div class="monthswindow" id="' + self.uid + '_monthwindow" + style="width: ' + self.nVisibleMonths * NWIDTH + 'px;">';
        html += '<div class="monthscontainer" id="' + self.uid + '_monthcontainer" + style="margin-left: -' + self.monthOffset * NWIDTH + 'px;">';

        let curMonth = self.minDate.getMonth() + 1;
        let curYear = self.minDate.getFullYear();

        for (let c = 0; c < self.nTotalMonths; c++) {
            html += self.renderMonth(curMonth, curYear);
            curMonth += 1;
            if (curMonth > 12) {
                curMonth = 1;
                curYear += 1;
            }
        }

        html += '</div>';  // monthcontainer
        html += '</div>';  // monthwindow

        let footNote;
        if (typeof self.footNote === "string") {
            footNote = self.footNote;
        } else if (typeof self.footNote === "function") {
            footNote = self.footNote();
        }
        if (footNote) {
            html += '<div class="popupFooter">';
            html += footNote;
            html += '</div>';
        }

        html += '</div>';   // datePickerPopup
        html += '</div>';   // fullscreenPopup
        return html;
    },
    setMinDate: function (minDate) {
        const self = this;
        if (minDate) {
            self.minDate = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate());
        } else {
            self.setMinDate(new Date());
        }
        self.invalidate();
    },
    setMaxDate: function (maxDate) {
        const self = this;
        if (maxDate) {
            self.maxDate = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate());
        } else {
            self.setMaxDate(new Date(self.minDate.getTime() + 86400000 * 730));
        }
        self.invalidate();
    },
    setSelDate: function (selDate) {
        const self = this;
        let newDate;
        let newDateT, oldDateT;
        if (typeof selDate === "number") {
            const dUTC = new Date(selDate * 86400000);
            newDate = new Date(dUTC.getUTCFullYear(), dUTC.getUTCMonth(), dUTC.getUTCDate());
            newDateT = newDate.getTime();
        } else if (selDate instanceof Date) {
            if (selDate.getUTCHours() === 0) {
                newDate = new Date(selDate.getUTCFullYear(), selDate.getUTCMonth(), selDate.getUTCDate());
            } else {
                newDate = new Date(selDate.getFullYear(), selDate.getMonth(), selDate.getDate());
            }
            newDateT = newDate.getTime();
        } else {
            newDate = null;
            newDateT = null;
        }
        if (self.selDate) {
            oldDateT = self.selDate.getTime();
        } else {
            oldDateT = null;
        }
        if (oldDateT !== newDateT) {
            self.selDate = newDate;
            if (self.onDate) self.onDate(newDate);
            self.invalidate();
        }
    },
    setHoverDate: function (hoverDate, db) {
        const self = this;
        let newDate;
        let newDateT, oldDateT;
        if (hoverDate) {
            newDate = new Date(hoverDate.getFullYear(), hoverDate.getMonth(), hoverDate.getDate());
            newDateT = newDate.getTime();
        } else {
            newDate = null;
            newDateT = null;
        }
        if (self.hoverDate) {
            oldDateT = self.hoverDate.getTime();
        } else {
            oldDateT = null;
        }
        if (oldDateT !== newDateT) {
            // console.log("xx:" +db + " - " + newDate);
            self.hoverDate = newDate;
            self.invalidate();
        }
    },
    setEnabled: function (enabled) {
        const self = this;
        if (self.enabled !== enabled) {
            self.enabled = enabled;
            self.invalidate();
        }
    },
    showPopup: function () {
        const self = this;

        let curMonth = self.minDate.getMonth() + 1;
        let curYear = self.minDate.getFullYear();

        const maxMonth = self.maxDate.getMonth() + 1;
        const maxYear = self.maxDate.getFullYear();

        if (window.innerWidth < 500) {
            self.nVisibleMonths = 1;
        } else {
            self.nVisibleMonths = 2;
        }

        let c = 0;
        let terminated = false;
        while ((c < self.nVisibleMonths) ||
        ((c <= 24) && (!terminated))) {
            if ((maxMonth === curMonth) && (maxYear === curYear)) {
                terminated = true;
            }
            c += 1;
            curMonth += 1;
            if (curMonth > 18) {
                curMonth = 1;
                curYear += 1;
            }
        }

        self.nTotalMonths = c;


        self.calendarVisible = true;
        if (self.selDate) {
            self.monthOffset = (self.selDate.getFullYear() * 12 + self.selDate.getMonth()) -
                (self.minDate.getFullYear() * 12 + self.minDate.getMonth());
        } else {
            self.monthOffset = 0;
        }

        if (self.monthOffset > self.nTotalMonths - self.nVisibleMonths) self.monthOffset = self.nTotalMonths - self.nVisibleMonths;
        self.hoverDate = self.selDate;
        self.invalidate();
    },
    hidePopup: function () {
        const self = this;
        self.calendarVisible = false;
        self.invalidate();
    },
    updateScroll: function () {
        const self = this;
        setImmediate(function () {
            const container = document.getElementById(self.uid + '_monthwindow');
            container.scrollLeft = self.monthOffset * NWIDTH;
            container.scrollLeft += NWIDTH;
        });
    },
    scrollEffect: function () {
        const self = this;

        const speed = 35;

        const el = document.getElementById(self.uid + '_monthcontainer');
        if (!el) return;
        const style = el.currentStyle || window.getComputedStyle(el);
        let curMargin = style.marginLeft;
        curMargin = parseInt(curMargin, 10);
        const desiredMargin = -self.monthOffset * NWIDTH;

        let delta = desiredMargin - curMargin;
        if (delta > speed) delta = speed;
        if (delta < -speed) delta = -speed;

        const nextMargin = curMargin + delta;

        el.style.marginLeft = "" + nextMargin + "px";

        if (nextMargin !== desiredMargin) {
            setTimeout(function () {
                self.scrollEffect();
            }, 50);
        }
    },
    updatePlusLess: function () {
        const self = this;

        let el, c;

        el = document.getElementById(self.uid + '_less');
        if (el) {
            c = el.className;
            if (self.monthOffset > 0) {
                c = c.replace("disabled", "");
                c = c + " enabled";
            } else {
                c = c.replace("enabled", "");
                c = c + " disabled";
            }
            el.className = c;
        }

        el = document.getElementById(self.uid + '_plus');
        if (el) {
            c = el.className;
            if (self.monthOffset < self.nTotalMonths - self.nVisibleMonths) {
                c = c.replace("disabled", "");
                c = c + " enabled";
            } else {
                c = c.replace("enabled", "");
                c = c + " disabled";
            }
            el.className = c;
        }


    }

});


module.exports = DatePicker;

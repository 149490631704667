/*jslint node: true */
"use strict";
/*global window, navigator */

var coman = require('./coman.js');

var label = coman.createComponent({
    init: function(options) {
        var self=this;
        self.options.class.push("label");
    },
    render: function() {
        var self = this;
        var html;
        html = "";
        html += '<label ';
        if (self.options.for) {
            html += "for=\"" + self.options.for + "\" ";
        }
        html += "id=\"" + self.uid + "\" ";
        html += "class=\"" + self.options.class.join(" ") + "\" ";
        html += ">";
        html += self.encodeStr(self.options.text);
        html += "</label>";
        return html;
    }
});

module.exports = label;

"use strict";
/*jslint node: true */
/*global window, module, document, navigator */

const coman = require('./coman.js');

const ComboBox = coman.createComponent({
    init: function (options) {
        const self = this;
        self.enabled = true;
        self.popupVisible = false;
        self.selected = null;
        self.cbOptions = [];
    },
    render: function () {
        const self = this;
        let html = "<div ";

        html += " id=\"" + self.uid + "\"";

        // class
        html += " class=\"comboBox ";
        html += self.options.class.join(" ");
        html += "\" ";
        if (self.enabled) {
            html += self.addEvent("onClick", function (event) {
                if (self.popupVisible) {
                    self.hidePopup();
                } else {
                    self.showPopup();
                }
            });
        }

        html += ">";

        const classes = [];
        classes.push("inputBox");

        if (self.selectedOption === null) {
            classes.push("empty");
        }

        if (self.enabled) {
            classes.push("enabled");
        } else {
            classes.push("dissabled");
        }

        html += '<span id="' + self.uid + '_input' + '" ';
        html += 'class="' + classes.join(" ") + '" ';
        /*        if (self.enabled) {
                    html += self.addEvent("onClick", function(event) {
                        if (self.popupVisible) {
                            self.hidePopup();
                        } else {
                            self.showPopup();
                        }
                    });
                } */

        html += ">";
        if (self.selected !== null) {
            html += self.getSelectedLabel() + '&nbsp;';
        } else {
            html += "-";
        }
        html += "</span>";


        if ((self.popupVisible) && (self.enabled)) {
            html += self.renderPopup();
        }
        html += "</div>";

        return html;
    },
    rederComboDropDown: function () {
        const self = this;
        let html = "";

        html += '<div class="comboOptionsContainer"> ';

        self.cbOptions.forEach(function (option, idx) {
            function optionClick(ev, key) {
                self.setSelected(key);
                self.hidePopup();
            }

            const classes = ['comboboxOption'];
            html += '<div ';
            html += 'id="' + self.uid + '_option_' + idx + '" ';
            if (self.selected === option.key) {
                classes.push("selected");
            }
            html += 'class="' + classes.join(' ') + '" ';
            html += self.addEvent('onclick', optionClick, option.key);
            html += '>';
            html += option.label;
            html += "</div>";
        });

        html += '</div> ';

        return html;
    },
    renderPopup: function () {

        function getPosition() {
            let style = "";

            let el = document.getElementById(self.uid + '_input');
            // const elw = el.clientWidth;
            const elh = el.clientHeight;
            let ex, ey;

            /*
                        for (ex=0, ey=0;
                             el !== null;
                             ex += el.offsetLeft, ey += el.offsetTop, el = el.offsetParent);

            */


            const boundingRect = el.getBoundingClientRect();
            ex = boundingRect.left;
            ey = boundingRect.top;


            /*
            ex =  el.offsetLeft;
            ey = el.offsetTop;
            */

            el = document.getElementById(self.uid + '_input');
            const w = window.innerWidth;
            const h = window.innerHeight;
            const puw = 300;
            const puh = 300;

            if (ex + puw < w) {
                style += "left: " + ex + 'px; ';
            } else {
                style += "right: 0px; ";
            }

            if (ey + elh + puh > h) {
                style += "bottom: " + (h - ey + 2) + 'px; ';
            } else {
                style += "top: " + (ey + elh + 2) + 'px; ';
            }

            return 'style="' + style + '" ';
        }

        const self = this;

        let html = "";

        html += '<div class="fullScreenPopup" ';
        html += self.addEvent("onClick", function (event) {
            self.hidePopup();
            event.stopPropagation();
        });
        html += '>';

        html += '<div class="comboPopup" ';
        html += getPosition();
        html += self.addEvent("onClick", function (event) {
            event.stopPropagation();
        });
        html += '>';

        if (self.options.title) {
            html += '<div class="popupTitle">';
            html += self.encodeStr(self.options.title);
            html += '</div>';
        }

        const classes = ["closeButton"];

        html += '<div ';
        html += 'class="' + classes.join(' ') + '" ';
        html += self.addEvent('onclick', function () {
            self.hidePopup();
        });
        html += ">";
        html += "<span></span>";
        html += "</div>";

        html += self.rederComboDropDown();

        html += "</div>";


        html += '</div>';   // fullscreenPopup
        return html;
    },

    setEnabled: function (enabled) {
        const self = this;
        if (self.enabled !== enabled) {
            self.enabled = enabled;
            self.invalidate();
        }
    },
    showPopup: function () {
        const self = this;
        self.popupVisible = true;
        self.invalidate();
    },
    hidePopup: function () {
        const self = this;
        self.popupVisible = false;
        self.invalidate();
    },
    setSelected: function (key) {
        const self = this;
        const oldSelected = self.selected;
        self.selected = key;
        self.checkSelectedInOptions();
        if ((self.onChange) && (oldSelected !== self.selected)) self.onChange(self.selected);
        self.invalidate();
    },
    getSelected: function () {
        const self = this;
        return self.selected;
    },
    setOptions: function (cbOptions) {
        const self = this;
        const oldSelected = self.selected;
        self.cbOptions = JSON.parse(JSON.stringify(cbOptions));
        self.checkSelectedInOptions();
        if ((self.onChange) && (oldSelected !== self.selected)) self.onChange(self.selected);
        self.invalidate();
    },
    getSelectedLabel: function () {
        const self = this;
        if (!(self.cbOptions instanceof Array)) return null;
        for (let i = 0; i < self.cbOptions.length; i += 1) {
            if (self.cbOptions[i].key === self.selected) {
                return self.cbOptions[i].label;
            }
        }
        return null;
    },
    checkSelectedInOptions: function () {
        const self = this;
        let found = false;
        for (let i = 0; i < self.cbOptions.length; i += 1) {
            if (self.cbOptions[i].key === self.selected) {
                found = true;
            }
        }
        if ((!found) && (self.selected !== null)) {
            self.selected = null;
            self.invalidate();
        }
    }
});


module.exports = ComboBox;

/*jslint node: true */
"use strict";
/*global window, module, document, navigator */

const translations = require('../translations/translations.js');

function uid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() +  s4() +  s4() + s4() + s4() + s4() + s4();
}

var scriptSource = (function(scripts) {
    scripts = document.getElementsByTagName('script');
    var script = scripts[scripts.length - 1];

    if (script.getAttribute.length !== undefined) {
        return script.src;
    }

    return script.getAttribute('src', -1);
}());

// console.log(scriptSource);

var coman = {};

//Returns true if it is a DOM node
function isNode(o){
  return (
    typeof Node === "object" ? o instanceof Node :
    o && typeof o === "object" && typeof o.nodeType === "number" && typeof o.nodeName==="string"
  );
}

//Returns true if it is a DOM element
function isElement(o){
  return (
    typeof HTMLElement === "object" ? o instanceof HTMLElement : //DOM2
    o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName==="string"
);
}

coman.createComponent = function(def) {
    var constructor = function(parent, options) {
        options = options || {};
        var self = this;
        self.childs = [];

        // _.extend(self, def);
        Object.assign(self, def);

        self.options = JSON.parse(JSON.stringify(options));
        self.options.class = self.options.class || [];
        if (typeof self.options.class === "string") {
            self.options.class = [ self.options.class];
        }
        self.uid = uid();

        if (typeof parent === "string") {
            self.$rootDiv = document.getElementById(parent);
            if (!self.$rootDiv) {
                throw new Error("Parent not found with id: " +  parent);
            }
            self.parent = null;

        } else if (isElement(parent)) {
            self.$rootDiv = parent;
            self.parent = null;
        } else if ((typeof parent === "object") &&(parent.childs)) {
            parent.childs.push(self);
            parent.invalidate();
            self.parent = parent;
        }

        function isMobileDevice() {
            return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
        }

        let lastWindowHeight = window.innerHeight;
        if (!self.parent) {
            window.addEventListener("resize", function() {
                if (isMobileDevice()) {
                    return;
                }
                if (window.innerWidth === window.innerWidth && window.innerHeight !== lastWindowHeight) {
                    lastWindowHeight = window.innerHeight;
                    return; // No ejecutar el código de redimensionamiento si se desplegó el teclado.
                }
                self.invalidate();
            }, false);
        }

        self.$valid = true;
        self.invalidate= function() {
            if (self.parent) {
                self.parent.invalidate();
                return;
            }
            if (self.$valid) {
                self.$valid=false;
                setTimeout(function() {
                    self.$valid = true;
                    self.$doRender();
                }, 100);
            }
        };

        self.$doRender = function() {
            var html = "";
            if (self.$rootDiv) {
                if (self.eventHandles != null) {
                    self.eventHandles.forEach( function(idEvent) {
                        delete coman.eventHandles[idEvent];
                    });
                }
                self.eventHandles = [];
            }

            if (self.render) {
                html = html + self.render();
            } else {
                html = html + self.renderChilds();
            }
            if (self.$rootDiv) {
                self.$rootDiv.innerHTML=html;
            }
            return html;
        };

        self.renderChilds = function() {
            var html = "";
            if (self.childs != null) {
                self.childs.forEach(function(child) {
                    if (child.$doRender) {
                        html = html + child.$doRender();
                    }
                });
            }
            return html;
        };

        self.getLang = function() {
            if (self.options.lang) return self.options.lang;
            if (self.parent) return self.parent.getLang();
            return navigator.lang;
        };

        self.gettextCatalog = {};

        self.gettextCatalog.getString = function(s) {
            s = s.trim();
            var lang = self.getLang();
            if (self.options.translations &&
                self.options.translations[lang] &&
                self.options.translations[lang][s]) {
                return self.options.translations[lang][s];
            }
            if (translations &&
                translations[lang] &&
                translations[lang][s]) {
                return translations[lang][s];
            }
            if (self.options.translations &&
                self.options.translations.en &&
                self.options.translations.en[s]) {
                return self.options.translations.en[s];
            }
            if (translations &&
                translations.en &&
                translations.en[s]) {
                return translations.en[s];
            }
            if (self.parent) return self.parent.gettextCatalog.getString(s);
            return s;
        };

        self.gettextCatalog.getPlural = function(n, s1, s2) {
            s1=s1.trim();
            s2=s2.trim();
            function extract(n, arr) {
                n -=1;
                if (n>=arr.length) n=arr.length-1;
                if (n<0) n=arr.length-1;
                return arr[n];
            }

            var lang = self.getLang();
            if (self.options.translations &&
                self.options.translations[lang] &&
                self.options.translations[lang][s1]) {

                return extract(n, self.options.stranslation[lang][s1]);
            }
            if (translations &&
                translations[lang] &&
                translations[lang][s1]) {

                return extract(n, translations[lang][s1]);
            }
            if (self.options.translations &&
                self.options.translations.en &&
                self.options.translations.en[s1]) {

                return extract(n, self.options.stranslation.en[s1]);
            }
            if (translations &&
                translations.en &&
                translations.en[s1]) {

                return extract(n, translations.en[s1]);
            }

            if (self.parent) return self.parent.gettextCatalog.getPlural(n, s1, s2);
            return extract(n, [s1, s2]);
        };

        self.encodeStr = function(rawStr) {
            var encodedStr = rawStr.replace(/[\u00A0-\u9999<>\&]/gim, function(i) {
               return '&#'+i.charCodeAt(0)+';';
            });
            return encodedStr;
        };

        var hashCode = function(S) {
          S = S.toString();
          var hash = 0, i, chr, len;
          if (S.length === 0) return hash;
          for (i = 0, len = S.length; i < len; i++) {
            chr   = S.charCodeAt(i);
            hash  = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
          }
          hash=hash.toString(16);
          if (hash[0] === '-') hash = hash.substring(1);
          return hash;
        };

        self.addEvent = function(eventName, fn, param) {
            var root = self;
            while (root.parent) root=root.parent;

            var idEvent = "h_" + self.uid + "_" + hashCode(fn);
            root.eventHandles.push(idEvent);

            coman.eventHandles[idEvent] = function() {
                fn.apply(self, arguments);
            };

            if (typeof param === 'object') {

            }
            var eventParam = param;
            if (param instanceof Date || typeof param === 'object') {
                eventParam = JSON.stringify(param);
            } else if (!Number.isInteger(eventParam)) {
                eventParam = '"' + eventParam + '"';
            }
            var html= eventName + "='" + "coman.eventHandles." + idEvent + "(event, " + eventParam + ")' ";

            // var html= eventName + "='" + "coman.eventHandles." + idEvent + "(event, "+ ((typeof param === 'object') ? JSON.stringify(param) : param) + ")' ";
            return html;

        };

        self.lang= self.getLang();
        if (self.init) self.init(options);
        self.invalidate();
    };

    return constructor;
};

coman.eventHandles = {};

window.coman = coman;

module.exports = coman;

/*jslint node: true */
"use strict";
/*global window, navigator */

const coman = require('./coman.js');
const Label = require('./label.js');

const PromotionCodeInput = coman.createComponent({

    init: function () {

        this.options.class = ["promotion-code-container"];

        this.promotionCodeLabelText = this.gettextCatalog.getString('Promotion code');

        this.promotionCodeLabel = new Label(this, {
            for: this.uid + "-input",
            text: this.promotionCodeLabelText + ':',
            classes: ["promotion-code-label"]
        });

    },

    render: function () {

        const self = this;

        const events = self.addEvent('onkeyup', function () {
            const element = document.getElementById(this.uid + '-input');
            if (self.onChange != null) {
                self.onChange(element.value);
            }
            if (self.parent != null &&
                self.parent.options != null) {
                self.parent.options.promotionCode = element.value;
            }
            if (self.options != null) {
                self.options.promotionCode = element.value;
            }
        });

        let promotionCode;
        if (self.parent != null &&
            self.parent.options != null &&
            self.parent.options.promotionCode) {
            promotionCode = self.parent.options.promotionCode;
        }
        if (self.options != null &&
            self.options.promotionCode) {
            promotionCode = self.parent.options.promotionCode;
        }

        const input = `<input type="text"
            id="${self.uid}-input"
            placeholder="${self.promotionCodeLabelText}"
            type="text"
            value="${promotionCode || ''}"
            ${events}
        >`;

        return `<div
             class="${self.options.class.join(' ')}"
             id="${self.uid}">
             ${self.promotionCodeLabel.render()}
             ${input}
        </div>`
    },

    setPromotionCode: function (promotionCode) {
        const element = document.getElementById(this.uid + '-input');
        element.value = promotionCode;
    }

});

module.exports = PromotionCodeInput;

/*jslint node: true */
"use strict";
/*global module, document, window */

const coman = require('./coman.js');
const StayDates = require('./stay_dates.js');
const Persons = require('./persons.js');
const CategoriesSelector = require('./categories_selector.js');
const Facilities = require('./facilities.js');
const Service = require('./service.js');
const PromotionCodeInput = require('./promotion-code-input.js');
const Button = require('./button.js');
const moment = require('moment');

const MasterWidget = coman.createComponent({
    init: function (options) {
        const self = this;
        self.options = Object.assign({
            categoryGroupIds: [],
            categoryIds: [],
            facilityIds: [],
            automaticCheckOut: true,
            maxStay: 28
        }, self.options);

        self.options.class.push('widgetBookingContainer');

        if (self.options.promotionCodeInput === true) {
            self.options.class.push('promotion-code-enabled');
        }

        self.$rootDiv.className += self.options.class.join(" ");
        self.promotionCode = self.options.promotionCode;
        self.err = null;
        self.info = null;

        if (self.options.idProperty != null) {
            self.service = new Service(options);
            self.rsv4Active = true;
        }

        if (self.options.propertyGroupId != null) {
            self.service = new Service(options);
            self.mcsActive = true;
        }

        self.selectedCategories = [];

        const categoriesVisible = (options.idProperty || options.propertyGroupId || options.propertyId);

        self.categories = new CategoriesSelector(this, {
            title: self.gettextCatalog.getString("Select categories"),
            dropdown: options.dropdown,
            visible: categoriesVisible != null
        });

        self.categories.onChange = function (cats) {
            self.selectedCategories = cats.map(function (idCategory) {
                return idCategory.toString();
            });
            self.updateAvailability();
        };
        self.dates = new StayDates(this, self.options.stayDates || {});
        self.persons = new Persons(this, self.options.persons || {});
        self.facilities = new Facilities(this, self.options.facilities || {});
        self.facilities.onChange = function (facilities) {
            self.selectedFacilities = facilities.map(function (idFacility) {
                return idFacility.toString();
            });
            self.updateAvailability();
        };

        if (self.options.promotionCodeInput === true) {
            self.promotionCodeInput = new PromotionCodeInput(this);
        }

        self.bSearch = new Button(this, {
            text: self.gettextCatalog.getString("Search"),
            class: ["searchButton"]
        });

        self.bSearch.onClick = self.search.bind(self);
        self.bSearch.isEnabled = self.isButtonEnabled.bind(self);

        if (self.service != null) {
            self.service.getInfo(self.getLang())
                .then(info => {
                    self.onInfo(info);
                }, err => {
                    self.err = err;
                });
        }
    },
    isButtonEnabled: function () {
        const self = this;
        if (!self.dates.valid()) {
            return false;
        }
        if (!self.persons.valid()) {
            return false;
        }
        return true;
    },
    onInfo: function (info) {
        const self = this;

        // PARSE INFO FROM MCS
        if (self.mcsActive) {
            info.categories = info.products.reduce((acc, product) => {
                const accommodationType = info.accommodationTypes.find((accommodationTypeIt) => product.accommodationTypesUuids.includes(accommodationTypeIt.uuid));
                if (acc.findIndex((category) => category.idCategory === accommodationType.genericType) === -1) {
                    acc.push({
                        idCategory: accommodationType.genericType,
                        name: self.gettextCatalog.getString('genericType_' + accommodationType.genericType)
                    });
                }
                return acc;
            }, []);

            info.categories = info.categories.sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));

            Object.values(info.categories).forEach(function (c) {
                c.idCategory = c.idCategory.toString();
                if (c.idCategoryGroup) {
                    c.idCategoryGroup = c.idCategoryGroup.toString();
                }
            });
        }

        self.info = info;

        // Normalize categories to string.
        self.info.products.forEach(function (p) {
            if (p.categories != null) {
                p.categories = p.categories.map(function (idCategory) {
                    return idCategory.toString();
                });
            }
            if (self.mcsActive && p.genericTypes != null) {
                p.categories = p.genericTypes;
            }
        });

        // Calculate max persons;
        let maxPersons;
        if (info.maxPersons) {
            maxPersons = info.maxPersons;
        } else {
            maxPersons = info.rooms.reduce(function (mem, r) {
                const p = (r.capacity.adults || 0) + (r.capacity.child || 0) + (r.capacity.baby || 0);
                if (p > mem) mem = p;
                return mem;
            }, 2);
        }


        let maxAdults;
        if (info.maxAdults) {
            maxAdults = info.maxAdults;
        } else {
            maxAdults = info.rooms.reduce(function (mem, r) {
                const p = (r.capacity.adults || 0);
                if (p > mem) mem = p;
                return mem;
            }, 2);
        }

        self.persons.setMaxPersons(maxPersons);
        self.persons.setMaxAdults(maxAdults);

        if (!self.initialAgesSet) {
            if (self.options.guestAges) {
                self.persons.setAges(self.options.guestAges);
            }
            self.initialAgesSet = true;
        }

        // UPDATE the categories
        self.selectedCategories = [];

        self.options.categoryGroupIds = self.options.categoryGroupIds.map(function (categoryGroupId) {
            return categoryGroupId.toString();
        });

        self.options.categoryIds = self.options.categoryIds.map(function (categoryId) {
            return categoryId.toString();
        });

        if (info.categoryGroups != null) {
            info.categoryGroups.forEach(function (cg) {
                cg.idCategoryGroup = cg.idCategoryGroup.toString();
            });
        }

        info.categories.forEach(function (c) {
            c.idCategory = c.idCategory.toString();
            if (c.idCategoryGroup) {
                c.idCategoryGroup = c.idCategoryGroup.toString();
                if (self.options.categoryGroupIds.includes(c.idCategoryGroup)) {
                    self.selectedCategories.push(c.idCategory);
                    return;
                }
            }
            if (self.options.categoryIds.includes(c.idCategory)) {
                self.selectedCategories.push(c.idCategory);
                return;
            }
        });

        // If none selected, select all.
        if (self.selectedCategories.length === 0) {
            info.categories.forEach(function (c) {
                self.selectedCategories.push(c.idCategory);
            });
        }

        // Start Legacy code
        // If is old version, we calculate facilities for new room
        // in new versions it is not necessare
        // We maintain this condition for reverse compatibility

        if (info.rooms) {
            info.products.forEach(info.products, function (product) {
                product.facilities = [];
                product.rooms.forEach(function (idAccommodation) {
                    const accommodation = _.findWhere(info.rooms, {idRoom: idAccommodation});
                    if (!accommodation) return;
                    accommodation.facilities.forEach(function (facility) {
                        const idFacility = facility.idFacility.toString();
                        if (!product.facilities.includes(idFacility)) {
                            product.facilities.push(idFacility);
                        }
                    });
                });
            });
        }

        // End Legacy Code

        self.selectedFacilities = [];

        if (info.facilities != null) {
            info.facilities.forEach(function (f) {
                if (self.options.facilityIds.includes(f.idFacility.toString())) {
                    self.selectedFacilities.push(f.idFacility.toString());
                    return;
                }
            });
        }

        self.categories.setInfo(info);

        self.categories.setSelectedCategories(self.selectedCategories);
        self.categories.setCategoryGroups(self.expandedCategoryGroups);

        self.facilities.setInfo(info);
        self.facilities.setSelectedFacilities(self.selectedFacilities);

        self.updateAvailability();
        self.invalidate();
    },
    updateAvailability: function () {

        function intersectMatrix(m1, m2) {
            if ((!m1) && (!m2)) return null;
            if (!m1) {
                return JSON.parse(JSON.stringify(m2));
            }
            if (!m2) {
                return JSON.parse(JSON.stringify(m1));
            }
            let i;
            const out = {
                firstBookableDate: Math.min(m1.firstBookableDate, m2.firstBookableDate),
                matrix: []
            };

            const L = Math.max(m1.firstBookableDate + m1.matrix.length - out.firstBookableDate,
                m2.firstBookableDate + m2.matrix.length - out.firstBookableDate);
            for (i = 0; i < L; i += 1) {
                out.matrix[i] = 0;

                const i1 = i + out.firstBookableDate - m1.firstBookableDate;
                if (i1 >= 0 && i1 < m1.matrix.length) {
                    out.matrix[i] = out.matrix[i] | m1.matrix[i1];
                }

                const i2 = i + out.firstBookableDate - m2.firstBookableDate;
                if (i2 >= 0 && i2 < m2.matrix.length) {
                    out.matrix[i] = out.matrix[i] | m2.matrix[i2];
                }

            }

            return out;
        }

        function processAvailability(availability) {
            if (!self.info) return;
            let maxStay = self.options.maxStay;
            self.info.products.forEach(function (p) {
                const productAvailability = availability[(p.idProduct || p.productUuid)];
                if (productAvailability && productAvailability.matrix != null && productAvailability.firstBookableDate != null &&
                    (p.categories == null || p.categories.some(categoryId => self.selectedCategories.includes(categoryId))) &&
                    (p.facilities == null || p.facilities.every(categoryId => self.selectedFacilities.length === 0 || self.selectedFacilities.includes(categoryId)))) {
                    if (!productAvailability.matrix.some((checkin) => checkin > 0)) {
                        return;
                    }
                    if (typeof productAvailability.firstBookableDate !== "number") {
                        productAvailability.firstBookableDate = Math.round(moment(productAvailability.firstBookableDate).unix() / 86400);
                    }
                    a = intersectMatrix(a, productAvailability);
                    if (productAvailability.maxStay != null && productAvailability.maxStay > maxStay) {
                        maxStay = productAvailability.maxStay;
                    }
                }
            });

            if (!a) {
                a = {
                    firstBookableDate: Math.floor(moment().unix() / 86400),
                    matrix: [0],
                    maxStay: maxStay
                };
            } else {
                a.maxStay = maxStay;
            }

            self.dates.maxStay = maxStay;

            if (self.options.checkin) {
                self.dates.checkIn.setSelDate(new Date(self.options.checkin));
                self.initialSet = true;
            }

            if (self.options.checkout) {
                self.dates.checkOut.setSelDate(new Date(self.options.checkout));
                self.initialSet = true;
            }

            self.dates.setAvailability(a);
            if (!self.dates.checkinIsSelectable(self.dates.checkinDate)) {
                self.dates.checkIn.setSelDate(null);
                self.dates.checkOut.setSelDate(null);
            }
            if (!self.dates.checkoutIsSelectable(self.dates.checkoutDate)) {
                self.dates.checkOut.setSelDate(null);
            }
        }

        const self = this;
        let a = null;

        if (self.service != null) {
            self.service.getAvailability()
                .then(availability => {
                    processAvailability(availability);
                }, err => {
                    self.err = err;
                });
        }

    },
    search: function () {
        const self = this;
        let url = self.options.url;
        if (url[url.length - 1] != '/') {
            url += '/';
        }
        url += 'search?';
        url += '&checkin=' + moment(self.dates.checkinDate).format('YYYY-MM-DD');
        url += '&checkout=' + moment(self.dates.checkoutDate).format('YYYY-MM-DD');
        url += '&categoryIds=' + self.selectedCategories.join(',');
        url += '&guestAges=' + self.persons.getAges().join(',');
        url += '&lang=' + self.getLang();
        url += '&facilityIds=' + self.facilities.selectedFacilities.join(',');
        if (self.options.promotionCode) {
            url += '&promotionCode=' + self.options.promotionCode;
        }
        if (self.options.ra) {
            url += '&ra=' + self.options.ra;
        }
        if (self.options.guid) {
            url += '&guid=' + self.options.guid;
        }

        window.open(url, self.options.target || '_self');

    }
});

MasterWidget.StayDates = StayDates;
MasterWidget.CategoriesSelector = CategoriesSelector;
MasterWidget.Persons = Persons;
MasterWidget.Facilities = Facilities;
MasterWidget.PromotionCodeInput = PromotionCodeInput;
MasterWidget.Service = Service;

module.exports = MasterWidget;

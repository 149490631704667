/*jslint node: true */
"use strict";
/*global window, navigator */

const coman = require('./coman.js');

const Button = coman.createComponent({
    init: function (options) {
        const self = this;
        self.enabled = true;
        self.options.class.push("button");
    },
    render: function () {

        const self = this;
        let enabled = self.enabled;
        let html = '';

        // const classes = clone(self.options.class);
        const classes = JSON.parse(JSON.stringify(self.options.class));
        if (enabled) {
            classes.push("enabled");
        } else {
            classes.push("disabled");
        }
        html += '<button type="button" ';
        html += "class=\"" + classes.join(" ") + "\" ";
        html += "id=\"" + self.uid + "\" ";
        html += self.addEvent('onClick', function () {
            if (self.onClick) self.onClick();
        });

        if (typeof self.isEnabled === 'function') {
            enabled = self.isEnabled();
        }
        if (!enabled) {
            html += 'disabled ';
        }
        html += ">";
        html += self.encodeStr(self.options.text);
        html += "</button>";
        return html;
    },
    setEnabled: function (enabled) {
        const self = this;
        if (enabled !== self.enabled) {
            self.enabled = enabled;
            self.invalidate();
        }
    }
});

module.exports = Button;

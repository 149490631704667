"use strict";
/*jslint node: true */
/*global module, document */

const DatePicker = require('./date_picker.js');
const Label = require('./label.js');
const coman = require('./coman.js');
const moment = require('moment');

const StayDates = coman.createComponent({
    init: function (options) {
        const self = this;

        self.options = Object.assign( {
           checkInMaxDate: 730
        }, self.options);

        this.checkInLabel = new Label(this, {
            for: self.uid + "_checkin",
            text: self.gettextCatalog.getString("Check-in: "),
            class: ["checkinLabel"]
        });

        this.checkIn = new DatePicker(this, {
            classes: ["checkin"],
            title: self.gettextCatalog.getString("Check-in Date")
        });

        this.checkOutLabel = new Label(this, {
            for: self.uid + "_checkout",
            text: self.gettextCatalog.getString("Check-out: "),
            class: ["checkoutLabel"]
        });

        this.checkOut = new DatePicker(this, {
            class: ["checkout"],
            title: self.gettextCatalog.getString("Check-out Date"),
        });

        self.checkIn.setMinDate(moment().toDate());
        self.checkIn.setMaxDate(moment().add(self.options.checkInMaxDate, 'days').toDate());

        this.checkOut.footNote = self.checkoutFootNote.bind(self);
        this.checkOut.dayClasses = self.checkoutDayClasses.bind(self);
        this.checkOut.isSelectable = self.checkoutIsSelectable.bind(self);
        this.checkIn.dayClasses = self.checkinDayClasses.bind(self);
        this.checkIn.isSelectable = self.checkinIsSelectable.bind(self);
        this.checkOut.onDate = function (d) {
            let oldDate = null;
            if (self.checkoutDate) {
                oldDate = self.checkoutDate.getTime();
            }
            let newDate = null;
            if (d) {
                newDate = d.getTime();
            }
            if (oldDate !== newDate) {
                self.checkoutDate = d;
                self.updateEnables();
                self.invalidate();
                if (self.onCheckoutDate) {
                    const mD = moment(d);
                    const id = Date.UTC(mD.year(), mD.month(), mD.date()) / 86400000;
                    self.onCheckoutDate(new Date(id * 86400000));
                }
            }
        };
        this.checkIn.onDate = function (d) {
            let oldDate = null;
            if (self.checkinDate) {
                oldDate = self.checkinDate.getTime();
            }
            let newDate = null;
            if (d) {
                newDate = d.getTime();
            }
            if (oldDate !== newDate) {
                self.checkinDate = d;
                if (!self.checkoutIsSelectable(self.checkoutDate)) {
                    self.checkOut.setSelDate(null);
                }
                self.checkOut.setMinDate(d);

                const d1 = moment().add(730, 'days');
                const d2 = moment(d).add(self.maxStay || 28, 'days');
                self.checkOut.setMaxDate(moment.min(d1, d2).toDate());
                self.updateEnables();
                self.invalidate();
                if (self.onCheckinDate) {
                    const mD = moment(d);
                    const id = Date.UTC(mD.year(), mD.month(), mD.date()) / 86400000;
                    self.onCheckinDate(new Date(id * 86400000));
                }

                if (self.parent && self.parent.options.automaticCheckOut && (self.checkOut.enabled || self.checkOut.selDate == null)) {
                    self.checkOut.showPopup();
                }

            }
        };
        this.checkOut.setEnabled(false);
    },
    render: function () {
        const self = this;
        let html = '<div id="' + self.uid + '" class="checkinCheckoutContainer">';

        html += '<div class="checkinContainer">';
        html += self.checkInLabel.render();
        html += self.checkIn.render();
        html += '</div>';

        html += '<div class="checkoutContainer">';
        html += self.checkOutLabel.render();
        html += self.checkOut.render();
        html += '</div>';

        if (self.checkIn.selDate && self.checkOut.selDate) {
            html += '<div class="stayLen">';

            const params = {
                stayLen: moment(self.checkOut.selDate).diff(moment(self.checkIn.selDate), 'days')
            };

            const tpl = self.gettextCatalog.getPlural(params.stayLen, "${ stayLen }-night",
                "${ stayLen }-nights");

            // const compiled = _.template(tpl);
            // html += compiled(params);

            html += tpl.replace('${ stayLen }', params.stayLen);

            html += '</div>';
        }
        html += "</div>";
        return html;
    },
    checkoutDayClasses: function (d) {
        const self = this;
        let classes = [];
        let co = self.checkOut.hoverDate;
        if (self.checkinDate && (d.getTime() === self.checkinDate.getTime())) {
            classes.push("checkinDay");
        }
        if (self.checkinDate && co && (d.getTime() >= self.checkinDate.getTime()) &&
            (d.getTime() <= co.getTime())) {
            classes.push("stayDay");
        }
        if (self.checkinDate && co && (d.getTime() >= self.checkinDate.getTime()) &&
            (d.getTime() === co.getTime())) {
            classes.push("checkoutDay");
        }
        return classes;
    },
    checkinDayClasses: function (d) {
        const self = this;
        const classes = [];
        const ci = self.checkIn.hoverDate;
        if (ci && (d.getTime() === ci.getTime())) {
            classes.push("checkinDay");
        }
        return classes;
    },
    checkoutFootNote: function () {
        const self = this;
        if (!self.checkinDate) return ' ';
        if (!self.checkOut.hoverDate) return ' ';
        const mCin = moment(self.checkinDate);
        const mCout = moment(self.checkOut.hoverDate);
        const params = {
            stayLength: mCout.diff(mCin, 'days'),
            checkin: mCin.format('ddd DD MMM'),
            checkout: mCout.format('ddd DD MMM YYYY'),
        };
        const tpl = self.gettextCatalog.getPlural(params.stayLength, "from <strong>${ checkin }</strong> to <strong>${ checkout }</strong> (${ stayLength }-night stay)",
            "from <strong>${ checkin }</strong> to <strong>${ checkout }</strong> (${ stayLength }-night stay)");

        // const compiled = _.template(tpl);
        // const S = compiled(params);

        const S = tpl
            .replace('${ checkin }', params.checkin)
            .replace('${ checkout }', params.checkout)
            .replace('${ stayLength }', params.stayLength);

        return S;
    },
    checkoutIsSelectable: function (d) {
        const self = this;
        if (!self.checkinDate) return false;
        if (!d) return false;
        const mCin = moment(self.checkinDate);
        const iCin = Date.UTC(mCin.year(), mCin.month(), mCin.date()) / 86400000;

        let iCout;
        if (d instanceof Date) {
            const mD = moment(d);
            iCout = Date.UTC(mD.year(), mD.month(), mD.date()) / 86400000;
        } else if (typeof d === "number") {
            iCout = d;
        }

        const stayLen = iCout - iCin;
        if (stayLen <= 0) return false;
        if (stayLen > self.maxStay) return false;


        if ((!self.availability) || (!self.availability.matrix)) return true;


        const idxIn = iCin - self.availability.firstBookableDate;
        const idxOut = iCout - iCin - 1;

        if (idxIn < 0) return false;
        if (idxIn >= self.availability.matrix.length) return false;
        if (!self.availability.matrix[idxIn]) return false;

        if (idxOut < 0) return false;
        if (idxOut < 28) {
            return !!(self.availability.matrix[idxIn] & (1 << idxOut));
        } else {
            return (idxOut < self.maxStay);
        }

    },
    checkinIsSelectable: function (d) {
        const self = this;

        if (!self.availability || !self.availability.matrix) {
            return true;
        }

        let id;

        if (d instanceof Date) {
            const mD = moment(d);
            id = Date.UTC(mD.year(), mD.month(), mD.date()) / 86400000;
        } else if (typeof d === "number") {
            id = d;
        }

        const i = id - self.availability.firstBookableDate;
        if (i < 0) return false;
        if (i >= self.availability.matrix.length) return false;
        return !!self.availability.matrix[i];
    },
    setAvailability: function (availability) {

        const self = this;

        if (availability && availability.firstBookableDate) {
            let d = availability.firstBookableDate;
            if (typeof d === "number") {
                d = new Date(d * 86400000);
                d = new Date(d.toISOString().substring(0, 10));
            }
            self.checkIn.setMinDate(d);
        }

        self.availability = JSON.parse(JSON.stringify(availability));
        if (self.availability.firstBookableDate instanceof Date || typeof self.availability.firstBookableDate === 'string') {
            const mD = moment(self.availability.firstBookableDate);
            self.availability.firstBookableDate = Date.UTC(mD.year(), mD.month(), mD.date()) / 86400000;
        }

        self.maxStay = availability.maxStay;

        const checkOutMaxDate = new moment(self.checkinDate).add(self.maxStay, 'days');
        self.checkOut.setMaxDate(checkOutMaxDate.toDate());

        self.updateEnables();
        self.invalidate();
    },
    updateEnables: function () {
        const self = this;
        if (self.checkinIsSelectable(self.checkinDate)) {
            self.checkOut.setEnabled(true);
        } else {
            self.checkOut.setEnabled(false);
        }
    },
    valid: function () {
        const self = this;
        if (!self.checkinDate || !self.checkoutDate ||
            !self.checkinIsSelectable(self.checkinDate) ||
            !self.checkoutIsSelectable(self.checkoutDate)) {
            return false;
        }
        return true;
    }
});

module.exports = StayDates;

/*jslint node: true */
"use strict";
/*global window, navigator */

var MasterWidget = require('./master_widget.js');
// var translations = require('./translations.json');
var coman = require('./coman.js');

window.MasterWidget = MasterWidget;

window.addEventListener("load", function load(event){

},false);








// THIS FILE IS GENERATED AUTOMATICALLY. DO NOT EDIT
require("moment/locale/ca");
require("moment/locale/fr");
require("moment/locale/es");
require("moment/locale/it");
require("moment/locale/de");
require("moment/locale/nl");
require("moment/locale/da");
module.exports = {
 "ca": {
  "Promotion code": "Codi promocional",
  "genericType_PIT": "Parcel·la",
  "genericType_CAR": "Parcel·la de caravana",
  "genericType_TPI": "Parcel·la de tenda",
  "genericType_CAN": "Parcel·la canadenca",
  "genericType_BUN": "Bungalow",
  "genericType_MHO": "Mobile home",
  "genericType_BEN": "Bengali",
  "genericType_COW": "Propietari de caravana",
  "genericType_CMO": "Mòdul de client",
  "genericType_TOM": "Mòdul de tour operador",
  "genericType_ROO": "Habitació",
  "genericType_APA": "Apartament",
  "genericType_HOS": "Hostal",
  "genericType_CPA": "Pàrking de caravanes",
  "genericType_TPA": "Pàrking de turismes",
  "genericType_STORAGE": "Magatzem",
  "genericType_NPA": "Àrea no parcel·lada",
  "genericType_PBA": "Bany privat",
  "genericType_OTH": "Altres",
  "${ age } year old": [
   "${ age } any",
   "${ age } anys"
  ],
  "${ nAdults } adult": [
   "${ nAdults } adult",
   "${ nAdults } adults"
  ],
  "${ nChilds } child": [
   "${ nChilds } nen",
   "${ nChilds } nens"
  ],
  "${ stayLen }-night": [
   "${ stayLen } nit",
   "${ stayLen } nits"
  ],
  "Adults": "Adults",
  "Adults:": "Adults:",
  "Any accommodation": "Qualsevol allotjament",
  "Check-in Date": "Data d’entrada",
  "Check-in:": "Data d’entrada:",
  "Check-out Date": "Data de sortida",
  "Check-out:": "Data de sortida:",
  "Child ${ idx } age": "Edat nen ${ idx }",
  "Child Age": "Edat",
  "Children": "Nens",
  "Childs:": "Nens:",
  "Facilities": "Serveis",
  "from <strong>${ checkin }</strong> to <strong>${ checkout }</strong> (${ stayLength }-night stay)": [
   "Des de  <strong>${ checkin }</strong> fins el <strong>${ checkout }</strong> (${ stayLength } nit)",
   "Des de  <strong>${ checkin }</strong> fins el <strong>${ checkout }</strong> (${ stayLength } nits)"
  ],
  "loading...": "carregant…",
  "Search": "Buscar",
  "Select categories": "Seleccionar categories"
 },
 "fr": {
  "${ age } year old": [
   "${ age } an",
   "${ age } ans"
  ],
  "${ nAdults } adult": [
   "${ nAdults } adulte",
   "${ nAdults } adultes"
  ],
  "${ nChilds } child": [
   "${ nChilds } enfant",
   "${ nChilds } enfants"
  ],
  "${ stayLen }-night": [
   "${ stayLen } nuit",
   "${ stayLen } nuits"
  ],
  "Adults": "Adultes",
  "Adults:": "Adultes:",
  "Any accommodation": "Tous les hébergements",
  "Check-in Date": "Date d'arrivée",
  "Check-in:": "Date d'arrivée:",
  "Check-out Date": "Date de départ",
  "Check-out:": "Date de départ:",
  "Child ${ idx } age": "Âge de l'enfant ${ idx }",
  "Child Age": "âge",
  "Children": "Enfants:",
  "Childs:": "Enfants:",
  "Facilities": "Facilités",
  "from <strong>${ checkin }</strong> to <strong>${ checkout }</strong> (${ stayLength }-night stay)": [
   "À partir de <strong>${ checkin }</strong> jusqu'à <strong>${ checkout }</strong> (${ stayLength } nuit)",
   "À partir de <strong>${ checkin }</strong> jusqu'à <strong>${ checkout }</strong> (${ stayLength } nuits)"
  ],
  "loading...": "loading...",
  "Search": "Chercher",
  "Select categories": "Choisissez les catégories"
 },
 "es": {
  "Promotion code": "Código promocional",
  "genericType_PIT": "Parcela",
  "genericType_CAR": "Parcela de caravana",
  "genericType_TPI": "Parcela de tienda",
  "genericType_CAN": "Parcela canadiense",
  "genericType_BUN": "Bungalow",
  "genericType_MHO": "Mobile home",
  "genericType_BEN": "Bengalí",
  "genericType_COW": "Propietario caravana",
  "genericType_CMO": "Módulo cliente",
  "genericType_TOM": "Módulo tour operador",
  "genericType_ROO": "Habitación",
  "genericType_APA": "Apartamento",
  "genericType_HOS": "Hostal",
  "genericType_CPA": "Parking de caravanas",
  "genericType_TPA": "Parking de turismo",
  "genericType_STORAGE": "Almacén",
  "genericType_NPA": "Área no parcelada",
  "genericType_PBA": "Baño privado",
  "genericType_OTH": "Otros",
  "${ age } year old": [
   "${ age } año",
   "${ age } años"
  ],
  "${ nAdults } adult": [
   "${ nAdults } adulto",
   "${ nAdults } adultos"
  ],
  "${ nChilds } child": [
   "${ nChilds } niño",
   "${ nChilds } niños"
  ],
  "${ stayLen }-night": [
   "${ stayLen } noche",
   "${ stayLen } noches"
  ],
  "Adults": "Adultos",
  "Adults:": "Adultos:",
  "Any accommodation": "Cualquier alojamiento",
  "Check-in Date": "Fecha de entrada",
  "Check-in:": "Fecha de entrada:",
  "Check-out Date": "Fecha de salida",
  "Check-out:": "Fecha de salida:",
  "Child ${ idx } age": "Edad niño ${ idx }",
  "Child Age": "Edad",
  "Children": "Niños",
  "Childs:": "Niños:",
  "Facilities": "Servicios",
  "from <strong>${ checkin }</strong> to <strong>${ checkout }</strong> (${ stayLength }-night stay)": [
   "Desde el <strong>${ checkin }</strong> hasta el <strong>${ checkout }</strong> (${ stayLength } noche)",
   "Desde el <strong>${ checkin }</strong> hasta el <strong>${ checkout }</strong> (${ stayLength } noches)"
  ],
  "loading...": "cargando…",
  "Search": "Buscar",
  "Select categories": "Seleccionar categorías"
 },
 "en": {
  "genericType_PIT": "Pitch",
  "genericType_CAR": "Caravan pitch",
  "genericType_TPI": "Tent pitch",
  "genericType_CAN": "Canadian pitch",
  "genericType_BUN": "Bungalow",
  "genericType_MHO": "Mobile home",
  "genericType_BEN": "Bengali",
  "genericType_COW": "Caravan owner",
  "genericType_CMO": "Client module",
  "genericType_TOM": "Tou Operator module",
  "genericType_ROO": "Room",
  "genericType_APA": "Apartment",
  "genericType_HOS": "Hostel",
  "genericType_CPA": "Caravan Parking",
  "genericType_TPA": "Tourist Parking",
  "genericType_STORAGE": "Storage",
  "genericType_NPA": "Non parceled area",
  "genericType_PBA": "Private Bathroom",
  "genericType_OTH": "Other",
  "${ age } year old": [
   "${ age } year",
   "${ age } years"
  ],
  "${ nAdults } adult": [
   "${ nAdults } adult",
   "${ nAdults } adults"
  ],
  "${ nChilds } child": [
   "${ nChilds } child",
   "${ nChilds } children"
  ],
  "${ stayLen }-night": [
   "${ stayLen } night",
   "${ stayLen } nights"
  ],
  "Adults": "Adults",
  "Adults:": "Adults:",
  "Any accommodation": "Any accommodation",
  "Check-in Date": "Check-in Date",
  "Check-in:": "Check-in:",
  "Check-out Date": "Check-out Date",
  "Check-out:": "Check-out:",
  "Child ${ idx } age": "Child age ${ idx }",
  "Child Age": "Child Age",
  "Children": "Children",
  "Childs:": "Children:",
  "Facilities": "Facilities",
  "from <strong>${ checkin }</strong> to <strong>${ checkout }</strong> (${ stayLength }-night stay)": [
   "From <strong>${ checkin }</strong> till <strong>${ checkout }</strong> (${ stayLength } night)",
   "From <strong>${ checkin }</strong> till <strong>${ checkout }</strong> (${ stayLength } nights)"
  ],
  "loading...": "loading...",
  "Search": "Search",
  "Select categories": "Select categories"
 },
 "it": {
  "${ age } year old": [
   "${ age } anno",
   "${ age } anni"
  ],
  "${ nAdults } adult": [
   "${ nAdults } adulto",
   "${ nAdults } adulti"
  ],
  "${ nChilds } child": [
   "${ nChilds } bambino",
   "${ nChilds } bambini"
  ],
  "${ stayLen }-night": [
   "${ stayLen } notte",
   "${ stayLen } notti"
  ],
  "Adults": "Adulti",
  "Adults:": "Adulti:",
  "Any accommodation": "Qualsiasi alloggio",
  "Check-in Date": "Data di arrivo",
  "Check-in:": "Data di arrivo:",
  "Check-out Date": "Data di partenza",
  "Check-out:": "Data di partenza:",
  "Child ${ idx } age": "Bambino età ${ idx }",
  "Child Age": "età",
  "Children": "Bambini",
  "Childs:": "Bambini:",
  "Facilities": "Servizi",
  "from <strong>${ checkin }</strong> to <strong>${ checkout }</strong> (${ stayLength }-night stay)": [
   "Dal <strong>${ checkin }</strong> fino <strong>${ checkout }</strong> (${ stayLength } notte)",
   "Dal <strong>${ checkin }</strong> fino <strong>${ checkout }</strong> (${ stayLength } notti)"
  ],
  "loading...": "loading...",
  "Search": "Circando",
  "Select categories": "Seleziona la categoria"
 },
 "de": {
  "${ age } year old": [
   "${ age } Jahr",
   "${ age } Jahre"
  ],
  "${ nAdults } adult": [
   "${ nAdults } Erwachsen",
   "${ nAdults } Erwachsene"
  ],
  "${ nChilds } child": [
   "${ nChilds } Kind",
   "${ nChilds } Kinder"
  ],
  "${ stayLen }-night": [
   "${ stayLen } Nächt",
   "${ stayLen } Nächte"
  ],
  "Adults": "Erwachsene",
  "Adults:": "Erwachsene",
  "Any accommodation": "Beliebige Unterkunft",
  "Check-in Date": "Ankunftsdatum",
  "Check-in:": "Ankunftsdatum:",
  "Check-out Date": "Abreisedatum",
  "Check-out:": "Abreisedatum:",
  "Child ${ idx } age": "Alter des Kindes ${ idx }",
  "Child Age": "Alter",
  "Children": "Kinder",
  "Childs:": "Kinder:",
  "Facilities": "Diensten",
  "from <strong>${ checkin }</strong> to <strong>${ checkout }</strong> (${ stayLength }-night stay)": [
   "Von <strong>${ checkin }</strong> bis <strong>${ checkout }</strong> (${ stayLength } Nächt)",
   "Von <strong>${ checkin }</strong> bis <strong>${ checkout }</strong> (${ stayLength } Nächte)"
  ],
  "loading...": "loading...",
  "Search": "Suchen",
  "Select categories": "Wählen Sie die Kategorien"
 },
 "nl": {
  "${ nAdults } adult": [
   "${ nAdults } volwassene",
   "${ nAdults } volwassenen"
  ],
  "${ nChilds } child": [
   "${ nChilds } kind",
   "${ nChilds } kinderen"
  ],
  "${ stayLen }-night": [
   "${ stayLen } nacht",
   "${ stayLen } nachten"
  ],
  "Adults": "Volwassenen",
  "Adults:": "Volwassenen:",
  "Any accommodation": "Elke accommodatie",
  "Check-in Date": "Aankomstdatum",
  "Check-in:": "Aankomstdatum:",
  "Check-out Date": "Vertrekdatum",
  "Check-out:": "Vertrekdatum:",
  "Child ${ idx } age": "Leeftijd kind ${ idx }",
  "Child Age": "Leeftijd",
  "Children": "Kinderen",
  "Childs:": "Kinderen:",
  "Facilities": "Faciliteiten",
  "from <strong>${ checkin }</strong> to <strong>${ checkout }</strong> (${ stayLength }-night stay)": [
   "Vanaf <strong>${ checkin }</strong> tot <strong>${ checkout }</strong> (${ stayLength } nacht)",
   "Vanaf <strong>${ checkin }</strong> tot <strong>${ checkout }</strong> (${ stayLength } nachten)"
  ],
  "loading...": "loading...",
  "Search": "Zoeken",
  "Select categories": "Selecteer categorieën"
 },
 "da": {
  "${ age } year old": [
   "${ age } år gammel",
   "${ age } år gammel"
  ],
  "${ nAdults } adult": [
   "${ nAdults } voksen",
   "${ nAdults } voksene"
  ],
  "${ nChilds } child": [
   "${ nChilds } barn",
   "${ nChilds } børn"
  ],
  "${ stayLen }-night": [
   "${ stayLen }-Nat",
   "${ stayLen }-Nætter"
  ],
  "Adults": "Volwassene",
  "Adults:": "Volwassenen:",
  "Any accommodation": "Overnatning",
  "Check-in Date": "Ankomstdato",
  "Check-in:": "Ankomstdato:",
  "Check-out Date": "Afrejsedato",
  "Check-out:": "Afrejsedato:",
  "Child ${ idx } age": "Barnets alder ${ idx }",
  "Child Age": "Barnets alder",
  "Children": "Børn",
  "Childs:": "Børn",
  "Facilities": "Faciliteter",
  "from <strong>${ checkin }</strong> to <strong>${ checkout }</strong> (${ stayLength }-night stay)": [
   "fra <strong>${ checkin }</strong> til <strong>${ checkout }</strong> (${ stayLength }-nat)",
   "fra <strong>${ checkin }</strong> til <strong>${ checkout }</strong> (${ stayLength }-nætter)"
  ],
  "loading...": "loading...",
  "Search": "efter",
  "Select categories": "Vælg kategorierne"
 }
}
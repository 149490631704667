/*jslint node: true */
"use strict";
/*global module, document */

const Label = require('./label.js');
const coman = require('./coman.js');
const ComboBox = require('./combobox.js');

const Persons = coman.createComponent({
    init: function (options) {
        const self = this;

        self.options = Object.assign({
            minAdults: 1,
            maxAdults: 8,
            nAdults: 2,
            nChilds: 0,
            childAges: []
        }, self.options);

        self.minAdults = self.options.minAdults;
        self.maxPersons = self.options.maxPersons;
        self.nAdults = self.options.nAdults;
        self.nChilds = self.options.nChilds;
        self.childAges = self.options.childAges;

        self.adultsLabel = new Label(this, {
            for: self.uid + "_adults",
            text: self.gettextCatalog.getString("Adults: "),
            classes: ["adultsLabel"]
        });
        self.childsLabel = new Label(this, {
            for: self.uid + "_childs",
            text: self.gettextCatalog.getString("Childs: "),
            classes: ["childsLabel"]
        });
        self.adultsCB = new ComboBox(this, {
            classes: ["adultsCB"],
            title: self.gettextCatalog.getString("Adults")
        });
        self.adultsCB.onChange = function (newVal) {
            self.nAdults = newVal;
            self.updateCombos();
            self.notifyChange();
        };
        self.adultsCB.setSelected(self.nAdults);

        self.childsCB = new ComboBox(this, {
            classes: ["childsCB"],
            title: self.gettextCatalog.getString("Children")
        });
        self.childsCB.onChange = function (newVal) {
            self.nChilds = newVal;
            self.updateCombos();
            self.notifyChange();
        };
        self.childsCB.setSelected(self.nChilds);

        self.agesCB = [];
        self.setMaxAdults(8);
        self.setMaxPersons(8);
        self.updateCombos();
    },
    render: function () {
        const self = this;
        let html = "";

        html += '<div class="peopleContainer">';

        html += '<div class="adultsContainer">';
        html += self.adultsLabel.render();

        html += self.adultsCB.render();
        html += '</div>';

        html += '<div class="childsGroupContainer">';
        html += '<div class="childsContainer">';
        html += self.childsLabel.render();

        html += self.childsCB.render();
        html += '</div>';

        html += '<div class="childAgeGroupContainer">';

        for (let i = 0; i < self.nChilds; i += 1) {
            html += '<div class="childAgeContainer">';

            html += '<label ';
            html += 'for="' + self.agesCB[i].uid + '" ';
            html += 'class="childAgeLabel" ';
            html += ">";
            const tpl = self.gettextCatalog.getString("Child ${ idx } age");
            // const compiled = template(tpl);
            // html += compiled({idx: i + 1});
            html += tpl.replace('${ idx }', i + 1);
            html += "</label>";


            html += self.agesCB[i].render();
            html += '</div>';
        }
        html += '</div>'; // childAgesContainer

        html += '</div>'; // childsGroupContainer

        html += '</div>'; // peopleContainer

        return html;
    },
    setMaxPersons: function (maxPersons) {
        const self = this;
        self.maxPersons = maxPersons;
        self.invalidate();
    },
    setMaxAdults: function (maxAdults) {
        const self = this;
        self.maxAdults = maxAdults;
        self.updateCombos();
        self.invalidate();
    },
    setMinAdults: function (minAdults) {
        const self = this;
        self.minAdults = minAdults;
        self.updateCombos();
        self.invalidate();
    },
    updateCombos: function () {
        const self = this;
        let options = [];
        let params, tpl, compiled, o, label;

        if (self.nChilds > self.maxPersons - self.nAdults) {
            self.nChilds = self.maxPersons - self.nAdults;
        }
        for (let i = self.minAdults; i <= self.maxAdults; i++) {

            params = {
                nAdults: i
            };

            tpl = self.gettextCatalog.getPlural(params.nAdults, "${ nAdults } adult", "${ nAdults } adults");
            // compiled = template(tpl);
            // label = compiled(params);
            label = tpl.replace('${ nAdults }', params.nAdults);
            o = {
                key: i,
                label: label
            };

            options.push(o);

        }
        self.adultsCB.setOptions(options);
        self.adultsCB.setSelected(self.nAdults);

        options = [];
        for (let i = 0; i <= self.maxPersons - self.nAdults; i++) {

            params = {
                nChilds: i
            };

            tpl = self.gettextCatalog.getPlural(params.nChilds, "${ nChilds } child", "${ nChilds } childs");
            // compiled = template(tpl);
            // label = compiled(params);
            label = tpl.replace('${ nChilds }', params.nChilds);

            o = {
                key: i,
                label: label
            };

            options.push(o);

        }
        self.childsCB.setOptions(options);
        self.childsCB.setSelected(self.nChilds);

        function onChangeAge(i, newAge) {
            self.childAges[i] = newAge;
            self.notifyChange();
        }

        for (let i = 0; i < self.nChilds; i++) {


            self.agesCB[i] = new ComboBox(this, {
                classes: ["childAgeCB"],
                title: self.gettextCatalog.getString("Child Age")
            });

            options = [];
            for (let j = 0; j <= 17; j++) {

                tpl = self.gettextCatalog.getPlural(j, "${ age } year old","${ age } years old");
                // compiled = template(tpl);
                // label = compiled({age: j});
                label = tpl.replace('${ age }', j);

                o = {
                    key: j,
                    label: label
                };
                options.push(o);

            }
            self.agesCB[i].setOptions(options);
            self.agesCB[i].setSelected(self.childAges[i]);
            self.agesCB[i].onChange = onChangeAge.bind(self, i);
        }


    },
    valid: function () {
        const self = this;
        for (let i = 0; i < self.nChilds; i += 1) {
            if (typeof self.childAges[i] !== "number") return false;
        }
        return true;
    },
    getAges: function () {
        const self = this;
        const ages = [];
        if (self.nAdults < 0) {
            return null;
        }
        for (let i = 0; i < self.nAdults; i += 1) {
            ages.push(18);
        }
        if (typeof self.nChilds !== "number") return null;
        for (let i = 0; i < self.nChilds; i += 1) {
            if (typeof self.childAges[i] !== "number") return null;
            ages.push(self.childAges[i]);
        }
        return ages;
    },
    setAges: function (guestAges) {
        const self = this;
        if (!guestAges) return;
        self.nAdults = 0;
        self.nChilds = 0;
        self.childAges = [];
        guestAges.forEach(function (age) {
            age = parseInt(age, 10);
            if (age >= 18) {
                self.nAdults += 1;
            } else {
                self.nChilds += 1;
                self.childAges.push(age);
            }
        });
        self.updateCombos();
        self.notifyChange();
        self.invalidate();
    },
    notifyChange: function () {
        const self = this;
        const newAges = self.getAges();
        if (JSON.stringify(newAges) !== JSON.stringify(self.oldAges)) {
            self.oldAges = newAges;
            if (self.onChange) self.onChange(newAges);
        }
    }
});

module.exports = Persons;

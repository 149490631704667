/*jslint node: true */
"use strict";
/*global window, module, document, navigator */

const coman = require('./coman.js');
const moment = require('moment');

const Facilities = coman.createComponent({
    init: function (options) {
        const self = this;
        self.selectedFacilities = [];
    },
    render: function () {
        const self = this;
        moment.locale(self.lang);
        let html = "<div ";

        html += " id=\"" + self.uid + "\"";

        // class
        html += " class=\"facilities ";
        html += self.options.class.join(" ");
        html += "\" ";

        html += ">";

        if (self.info != null && Array.isArray(self.info.facilities) && self.info.facilities.length > 0) {
            html += '<div class="facilitiesTitle">';
            html += self.gettextCatalog.getString('Facilities');
            html += '</div>';

            self.info.facilities.forEach( function (facility) {
                html += self.renderFacility(facility);
            });
        }

        html += "</div>";

        return html;
    },

    renderFacility: function (f) {
        var self = this;
        var classes;
        var html = "";
        var val;

        function facilityClick(ev, idFacility) {
            idFacility = idFacility.toString();
            if (self.facilitySelected(idFacility)) {
                self.unselectFacility(idFacility);
            } else {
                self.selectFacility(idFacility);
            }
        }

        classes = ['facilityContainer'];
        html += '<div ';
        html += 'id="' + self.uid + '_facility_' + f.idFacility + '" ';
        html += 'class="' + classes.join(' ') + '" ';
        html += '>';

        classes = ['facilityGroupCB'];
        if (self.facilitySelected(f.idFacility)) {
            classes.push('selected');
            val = 'selected';
        } else {
            classes.push('unselected');
            val = 'unselected';
        }
        html += '<div ';
        html += 'id="' + self.uid + '_facilityCB_' + f.idFacility + '" ';
        html += 'class="' + classes.join(' ') + '" ';
        html += self.addEvent('onclick', facilityClick, f.idFacility);
        html += '>';
        html += '<span>' + val + '</span>';

        html += '</div>'; // categoryGroupCB

        classes = ['facilityLabel'];
        html += '<label ';
        html += 'id="' + self.uid + '_categoryLabel_' + f.idFacility + '" ';
        html += 'class="' + classes.join(' ') + '" ';
        html += self.addEvent('onclick', facilityClick, f.idFacility);
        html += '>';

        html += f.name;

        html += '</label>'; // label

        html += '</div>'; // categoryContainer
        return html;
    },
    selectFacility: function (idFacility) {
        idFacility = idFacility.toString();
        const self = this;
        if (!self.selectedFacilities.includes(idFacility)) {
            self.selectedFacilities.push(idFacility);
            self.invalidate();
            if (self.onChange) self.onChange(self.selectedFacilities);
        }
    },
    unselectFacility: function (idFacility) {
        idFacility = idFacility.toString();
        const self = this;
        const idx = self.selectedFacilities.indexOf(idFacility);
        if (idx >= 0) {
            self.selectedFacilities[idx] = self.selectedFacilities[self.selectedFacilities.length - 1];
            self.selectedFacilities.pop();
            self.invalidate();
            if (self.onChange) self.onChange(self.selectedFacilities);
        }
    },
    facilitySelected: function (idFacility) {
        idFacility = idFacility.toString();
        const self = this;
        return self.selectedFacilities.includes(idFacility);
    },
    setInfo: function (info) {
        const self = this;
        self.info = info;
        self.invalidate();
    },
    setSelectedFacilities: function (selectedFacilities) {
        const self = this;
        self.selectedFacilities = selectedFacilities.map(function (f) {
            return f.toString();
        });
        self.invalidate();
    }
});


module.exports = Facilities;
